<template>
  <div class="menuitems-main">
    <div class="cat-menu-cls" :class="newCateClassAdd" v-if="is_menu_category && cateMenus && cateMenus.length > 0">
      <nav class="category-menu-item-main px-0">
        <menu-category v-for="catmenu in cateMenus" :key=catmenu.id :menu="catmenu"
          v-on:setCategoryMenuId="openMenuCategoryList" :pagename="pageName"></menu-category>
      </nav>
    </div>

    <!-- item list tags showing  -->
    <!-- <div class="menu-class-sticky">
      <div class="sidebar" id="side-bar" v-if="listItemVeg.length > 0 || restTags.length > 0">
        <nav class="navigation extr-tags-list" id="menulist1">
          <ul class="mb-0 menu_tag">
            <li class="d-flex align-items-center">
              <div v-for="(list, i) in listItemVeg" class="tagslist">
                <a href="javascript:void(0);" :id="'menuitem' + i" class="rmvactv"
                  :class="selectType.length > 0 && selectType.includes(list) ? (list == '0' ? 'green' : list == '1' ? 'red' : '') : ''"
                  v-if="list != 2" @click="itemFilterClick(list, 'oldtag')">{{ (list == '0' ? 'Veg' : list ==
                    '1' ? 'Non-veg' : '') }}</a>
              </div>
              <div v-for="(tag, index) in restTags" class="tagslist">
                <a href="javascript:void(0);" :id="'menuitem' + tag.name" class="rmvactv"
                  @click="itemFilterClick(tag.name, 'newtag')"
                  :style="[selectType.includes(tag.name) ? { 'background': '' + tag.background_color + '!important' + '', 'color': tag.text_color + '!important' } : '']">{{
                    tag.name }}</a>
              </div>
            </li>
          </ul>
        </nav>
      </div>
    </div> -->

    <div class="menuitems-main-wrapper d-xl-flex">
      <!-------nav bar start------->
      <div class="side-menu-main">
        <ul id="menulist" role="tablist" aria-orientation="vertical">
          <li v-if="recommendedList && recommendedList.length > 0" class="mb-0">
            <!-- <a href="javascript:void(0)" id="menuitem0" class="rmvactv 80 side-menu-link"
              :class="{ active: (emptyMenuId != active_el) }" @click="activeMenu(0)">{{ $t('Recommended') }}</a> -->
            <button class="nav-link w-100 py-2 px-3 text-start rmvactv 80 side-menu-link active" id="menuitem0"
              data-bs-toggle="pill" data-bs-target="#item0"
              v-if="$auth.setting && $auth.setting.recommended_menu_name && $auth.setting.recommended_menu_name.length > 0"
              type="button" role="tab" aria-controls="item0" aria-selected="false">{{
                $t($auth.setting.recommended_menu_name) }}</button>
            <!-- <button class="nav-link w-100 py-2 px-3 text-start rmvactv 80 side-menu-link active" id="menuitem0"
              data-bs-toggle="pill" data-bs-target="#item0" v-else type="button" role="tab" aria-controls="item0"
              aria-selected="false">{{ $t('Recommended') }}</button> -->
          </li>

          <li v-for="(menu, index) in filteredData" :key="menu.restaurant_menu_id" class="mb-0">
            <!-- (menu.id == -1 ? index == menu.id : '') // This is only for grocerry (whats the meaning of this condition that we also dont know)
            ((index == 0 && menu.id != '' && menu.id != menu.restaurant_menu_id ? menu.restaurant_menu_id : '')) // Only for resto and milk (whats the meaning of this condition that we also dont know) -->
            <!-- :class="{ 'active': recommendedList.length <= 0 && menu.id == menu_catgory_id || (menu.id == -1 ? index == menu.id : '' || (index == 0 && menu.id != '' && menu.id != menu.restaurant_menu_id ? menu.restaurant_menu_id : '')) }" -->
            <div
              v-if="isPickupDelivery == '1' && $auth.setting && $auth.setting.product_type && $auth.setting.product_type.id == 2 && $auth.setting.store_selection == 0">
              <button class="nav-link w-100 py-2 px-3 text-start rmvactv 80 side-menu-link"
                :class="{ 'active': (recommendedList.length == 0 && menu.id == menu_catgory_id) || (recommendedList.length <= 0 && index == 0) && (recommendedList.length == 0 && index == 0) && (menu.id == -1 ? index == menu.id : '' || (index == 0 && menu.id != '' && menu.id != menu.restaurant_menu_id ? menu.restaurant_menu_id : '')) }"
                :id="'menuitem' + menu.restaurant_menu_id" data-bs-toggle="pill"
                :data-bs-target="'#item' + menu.restaurant_menu_id" type="button" role="tab"
                :aria-controls="'item' + menu.restaurant_menu_id" aria-selected="false"
                @click="activeMenu(menu.restaurant_menu_id)">{{ menu.menu_name }}</button>
            </div>

            <div v-else>
              <button class="nav-link w-100 py-2 px-3 text-start rmvactv 80 side-menu-link"
                :class="{ 'active': recommendedList.length <= 0 && index == 0 }"
                :id="'menuitem' + menu.restaurant_menu_id" data-bs-toggle="pill"
                :data-bs-target="'#item' + menu.restaurant_menu_id" type="button" role="tab"
                :aria-controls="'item' + menu.restaurant_menu_id" aria-selected="false"
                @click="activeMenu(menu.restaurant_menu_id)">{{ menu.menu_name }}</button>
            </div>
            <!-- <a href="javascript:void(0)" :id="'menuitem' + menu.restaurant_menu_id" class="rmvactv 88 side-menu-link"
              :class="{ active: emptyMenuId == menu.restaurant_menu_id }" @click="activeMenu(menu.restaurant_menu_id)"
              v-if="menu.item_counts != 0">{{ menu.menu_name }}</a> -->
          </li>
          <li v-for="mn in 6" :key="mn" v-if="menus.length == 0 && is_menu_skel_loader">
            <skeleton-loader-vue type="string" width="100%" height="29px" class="rmvactv 88 side-menu-link" />
          </li>

        </ul>
      </div>

      <!-------navbar end----------->
      <div class="main-content w-100 flex-grow-1 d-flex flex-column">
        <!-----skeleton Start layout-1---------->
        <div v-if="is_menu_skel_loader" class="menulength" id="itemmenumain">
          <section>
            <div class="media-heading">
              <h4 class="mb-4"><skeleton-loader-vue type="string" width="24%" height="20px" /></h4>
            </div>
            <div class="media-itms media-itms-theme5">
              <div class="item_detail noImgClass" right="true" v-for="prdt in 4" :key="prdt">
                <div class="media">
                  <a href="javascript:void(0)" id="element" class="btn btn-default show-modal">
                    <div class="media-body">
                      <h5 class="mt-0 me-5 item-name"><skeleton-loader-vue type="string" width="24%" height="20px" />
                      </h5>
                      <div class="prc-show">
                        <span class="mt-2 d-inline-block item-price">
                          <skeleton-loader-vue type="string" width="54px" height="24px" />
                        </span>
                      </div>
                      <p class="mb-0 mt-2 item-descr"> <skeleton-loader-vue type="string" width="100%" height="20px" />
                      </p>
                    </div>
                  </a>
                  <div class="img-btn">
                    <div class="item-img itemimg-squre">
                      <skeleton-loader-vue width="120" height="120" class="img-cls" style="border-radius: 5px" />
                    </div>
                    <div class="pgnme-cls btn-top">
                      <div class="nw-itm-btn">
                        <div class="sold-de">
                          <div class="icus"><button class="btn btn-sm"><skeleton-loader-vue type="string" width="50px"
                                height="24px" /></button></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>

        <!-----skeleton End layout-1---------->
        <div v-if="menus && menus.length > 0 && menusdata.length == 0" class="menulength" id="itemmenumain">
          <!--Recommanded Item End------>
          <div class="tab-content" id="v-pills-tabContent">
            <!--Recommanded Item start------>
            <section v-if="recommendedList.length > 0" id="item0" class="tab-pane fade show active" role="tabpanel"
              aria-labelledby="menuitem0">
              <div class="media-heading">
                <h4 class="mb-4">{{ $t($auth.setting.recommended_menu_name) }}</h4>
              </div>
              <div class="media-itms"
                :class="'media-itms-theme' + ((itemlayout == null || itemlayout == '') ? '1' : itemlayout)">
                <Product v-for="item in recommendedList" :key="item.item_id + item.restaurant_id" :item="item"
                  :right="true" menuId="0" v-on:openProductDetailModal="openProductDeails"
                  :restaurantID="item.restaurant_id" :restaurant_on_off="restaurant_on_off" :class="item.item_id"
                  :pagename="pageName" :itemlayout="(itemlayout == null || itemlayout == '') ? '1' : itemlayout"
                  :restautantDetails="restautantDetails"></Product>
              </div>
              <!-- :itemlayout="(itemlayout==null || itemlayout == '')? '1':itemlayout" -->

            </section>
            <template v-for="(menu, index) in filteredData">
              <section :id="'item' + menu.restaurant_menu_id"
                v-if="menu.item_counts != 0 && is_menu_skel_loader == false" :ref="'item' + menu.restaurant_menu_id"
                class="tab-pane fade" role="tabpanel" :aria-labelledby="'menuitem' + menu.restaurant_menu_id"
                tabindex="0" :class="{ 'show active': recommendedList.length <= 0 && index == 0 }">
                <div class="media-heading">
                  <h4 class="mb-4">{{ menu.menu_name }}</h4>
                </div>
                <div class="media-itms"
                  :class="'media-itms-theme' + ((itemlayout == null || itemlayout == '') ? '1' : itemlayout)">
                  <Product v-if="menu.length > 0" v-for="item in menu" :key="itemlayout + '-' + item.item_id"
                    :item="item" :right="true" :menuId="menu.restaurant_menu_id"
                    v-on:openProductDetailModal="openProductDeails" :restaurantID="item.restaurant_id"
                    :restaurant_on_off="restaurant_on_off" :class="item.item_id" :pagename="pageName"
                    :itemlayout="(itemlayout == null || itemlayout == '') ? '1' : itemlayout"
                    :restautantDetails="restautantDetails"></Product>
                  <!-- (itemlayout==null || itemlayout == '')? '1':itemlayout-->
                </div>
              </section>
            </template>
          </div>

          <div v-if="Object.keys(filteredData).length === 0">
            <div class="no-txt-fnd ">
              <img v-lazy="require('@/assets/images/no_search.png')" />
              <h6>{{ $t('No Item Found') }}</h6>
              <span>{{ $t('Please remove filters to see items.') }}</span>
            </div>
          </div>
        </div>
        <!---Category wise Menu item Open--->
        <!-- <div v-else-if="menusdata && Object.keys(filteredItemData).length != 0" class="menulength" id="itemmenumain 8"> -->
        <div v-else-if="menusdata && menusdata.length != 0" class="menulength" id="itemmenumain 8">
          <div>
            <section :id="'item' + menusdata.restaurant_menu_id" v-if="menusdata.menu_item_detail != 0">
              <div class="media-heading">
                <h4 class="mb-4">{{ menusdata.menu_name }}</h4>
              </div>
              <div class="media-itms"
                :class="'media-itms-theme' + ((itemlayout == null || itemlayout == '') ? '1' : itemlayout)">
                <Product v-for="item in filteredItemData" :key="itemlayout + '-' + item.item_id" :item="item"
                  :right="true" :menuId="menusdata.restaurant_menu_id" v-on:openProductDetailModal="openProductDeails"
                  :restaurantID="item.restaurant_id" :restaurant_on_off="restaurant_on_off" :class="item.item_id"
                  :pagename="pageName" :itemlayout="(itemlayout == null || itemlayout == '') ? '1' : itemlayout"
                  :restautantDetails="restautantDetails"></Product>
              </div>
            </section>
          </div>
          <div v-if="Object.keys(filteredItemData).length === 0 && is_menu_skel_loader == false">
            <div class="no-txt-fnd ">
              <img v-lazy="require('@/assets/images/no_search.png')" />
              <h6>{{ $t('No Item Found') }}</h6>
              <span>{{ $t('Please remove filters to see items.') }}</span>
            </div>
          </div>
        </div>
        <!---Category wise Menu item Open--->
        <div v-else class="home-res-not-found mt-4">
          <div class="no-txt-fnd" v-if="!is_menu_skel_loader">
            <img v-lazy="require('@/assets/images/no_search.png')" />
            <h6>{{ $t('No Item Found') }}</h6>
            <span>{{ $t('Please remove filters to see items.') }}</span>
          </div>
        </div>

        <div class="mt-auto">
          <!-- <Footer></Footer> -->
          <FooterSub></FooterSub>
        </div>
      </div>
    </div>
    <ProductDetails ref="childComponent" :restaurantID="restaurantID" :restaurant_on_off="restaurant_on_off"
      :pagename="pageName"></ProductDetails>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import Product from "@/components/Product.vue";
import ProductDetails from "@/components/ProductDetails.vue";
import MenuCategory from "@/components/MenuCategory.vue";
import VueSkeletonLoader from 'skeleton-loader-vue';
import Footer from '../components/Footer.vue';
import FooterSub from '../components/FooterSub.vue';
import ViewTagsListing from "../views/ViewTagsListing.vue";
import { integer } from "vuelidate/lib/validators";

export default {
  components: { Product, ProductDetails, MenuCategory, 'skeleton-loader-vue': VueSkeletonLoader, Footer, FooterSub, ViewTagsListing },
  props: ["restaurant_on_off", "restautantDetails", "search"],
  data() {
    return {
      menu_catgory_id: localStorage.getItem('setMenuCategoryId'),
      menus: [],
      restaurantID: "",
      selectedSection: 0,
      vendorID: "",
      date: "",
      starttime: "",
      productDetails: { type: Object },
      active_el: 0,
      loading: true,
      limit: 10,
      totalItems: 0,
      scrollPosition: 0,
      total_record: 20,
      skiped: 0,
      // search:'',
      pageName: 'itemslist',
      routerItemId: 0,
      routerItemIdStatus: true,
      is_menu_category: false,
      cateMenus: [],
      menuCategryId: 0,
      is_menu_skel_loader: true,
      menusdata: [],
      isSearchHide: true,
      itemlayout: '1',
      newCateClassAdd: '',
      itemTypeFilterKey: '',
      itemType: [],
      typeUniqNumbers: [],
      selectType: [],
      listItemVeg: [],
      restTags: [],
      recommendedList: [],
      emptyMenuId: '',
      menu_item_count: 0,
      testArray: [
        {
          "restaurant_cate_id": 1000,
          "restaurant_id": 3700,
          "cate_name": "category-1",
          "cate_description": "this is first cate",
          "menu":
            [
              {
                "restaurant_menu_id": 57585,
                "restaurant_id": 3700,
                "color_code": null,
                "menu_name": "Dental Care",
                "name": "Dental Care",
                "menu_description": "",
                "is_display_image": "1",
                "menu_image": "",
                "image": "",
                "item_counts": 18,
                "menu_item_detail": [
                  {
                    "restaurant_id": 3700,
                    "item_id": "1931653",
                    "is_featured": "0",
                    "item_name": "Closeup Complete ToothBrush Soft 1 Pc",
                    "item_description": "Pack Size: 1 Piece",
                    "image": "https://storage.googleapis.com/deonde-prod/media/Restaurant_Menu_Item_image/40818/3700/deonde_menu_16385124717.jpg",
                    "item_images": [
                      {
                        "image_name": "https://storage.googleapis.com/deonde-prod/media/Restaurant_Menu_Item_image/40818/3700/deonde_menu_16385124717.jpg"
                      }
                    ],
                    "item_tax": 0,
                    "custom_details": [

                    ],
                    "quantity": 999,
                    "time_slot": "",
                    "is_sold_out": "0",
                    "mrp": 6,
                    "price": 6,
                    "is_alcoholic": "0",
                    "item_type": "2",
                    "item_packaging_charge": "0",
                    "is_customization": 0,
                    "item_tags": [

                    ]
                  },
                  {
                    "restaurant_id": 3700,
                    "item_id": "1931654",
                    "is_featured": "0",
                    "item_name": "Colgate 360° Whole Mouth Clean Brush Medium With Tongue Cleaner    ",
                    "item_description": "Pack Size: 1 Piece",
                    "image": "https://storage.googleapis.com/deonde-prod/media/Restaurant_Menu_Item_image/40818/3700/deonde_menu_16385124726272.jpg",
                    "item_images": [
                      {
                        "image_name": "https://storage.googleapis.com/deonde-prod/media/Restaurant_Menu_Item_image/40818/3700/deonde_menu_16385124726272.jpg"
                      }
                    ],
                    "item_tax": 0,
                    "custom_details": [

                    ],
                    "quantity": 999,
                    "time_slot": "",
                    "is_sold_out": "0",
                    "mrp": 7,
                    "price": 7,
                    "is_alcoholic": "0",
                    "item_type": "2",
                    "item_packaging_charge": "0",
                    "is_customization": 0,
                    "item_tags": [

                    ]
                  },
                  {
                    "restaurant_id": 3700,
                    "item_id": "1931656",
                    "is_featured": "0",
                    "item_name": "Colgate Double Action Tooth Brush Medium",
                    "item_description": "Pack Size: 1 Piece",
                    "image": "https://storage.googleapis.com/deonde-prod/media/Restaurant_Menu_Item_image/40818/3700/deonde_menu_16385124723078.jpg",
                    "item_images": [
                      {
                        "image_name": "https://storage.googleapis.com/deonde-prod/media/Restaurant_Menu_Item_image/40818/3700/deonde_menu_16385124723078.jpg"
                      }
                    ],
                    "item_tax": 0,
                    "custom_details": [

                    ],
                    "quantity": 998,
                    "time_slot": "",
                    "is_sold_out": "0",
                    "mrp": 1,
                    "price": 1,
                    "is_alcoholic": "0",
                    "item_type": "2",
                    "item_packaging_charge": "0",
                    "is_customization": 0,
                    "item_tags": [

                    ]
                  },
                  {
                    "restaurant_id": 3700,
                    "item_id": "1931657",
                    "is_featured": "0",
                    "item_name": "Colgate Extra Clean Medium Tooth Brush",
                    "item_description": "Pack Size: 1 Piece",
                    "image": "https://storage.googleapis.com/deonde-prod/media/Restaurant_Menu_Item_image/40818/3700/deonde_menu_16385124728269.jpg",
                    "item_images": [
                      {
                        "image_name": "https://storage.googleapis.com/deonde-prod/media/Restaurant_Menu_Item_image/40818/3700/deonde_menu_16385124728269.jpg"
                      }
                    ],
                    "item_tax": 0,
                    "custom_details": [

                    ],
                    "quantity": 998,
                    "time_slot": "",
                    "is_sold_out": "0",
                    "mrp": 6,
                    "price": 6,
                    "is_alcoholic": "0",
                    "item_type": "2",
                    "item_packaging_charge": "0",
                    "is_customization": 0,
                    "item_tags": [

                    ]
                  },
                  {
                    "restaurant_id": 3700,
                    "item_id": "1931658",
                    "is_featured": "0",
                    "item_name": "Colgate Slim Soft Brush 0.01mm",
                    "item_description": "Pack Size: 1 Piece",
                    "image": "https://storage.googleapis.com/deonde-prod/media/Restaurant_Menu_Item_image/40818/3700/deonde_menu_16385124739461.jpg",
                    "item_images": [
                      {
                        "image_name": "https://storage.googleapis.com/deonde-prod/media/Restaurant_Menu_Item_image/40818/3700/deonde_menu_16385124739461.jpg"
                      }
                    ],
                    "item_tax": 0,
                    "custom_details": [

                    ],
                    "quantity": 998,
                    "time_slot": "",
                    "is_sold_out": "0",
                    "mrp": 6,
                    "price": 6,
                    "is_alcoholic": "0",
                    "item_type": "2",
                    "item_packaging_charge": "0",
                    "is_customization": 0,
                    "item_tags": [

                    ]
                  }

                ]
              },
              {
                "restaurant_menu_id": 57585,
                "restaurant_id": 3700,
                "color_code": null,
                "menu_name": "Spicy Delights",
                "name": "Spicy Delights",
                "menu_description": "",
                "is_display_image": "1",
                "menu_image": "",
                "image": "",
                "item_counts": 18,
                "menu_item_detail": [
                  {
                    "restaurant_menu_item_id": 2578826,
                    "restaurant_menu_id": 77708,
                    "is_featured": "0",
                    "item_name": "McSPICY PANEER",
                    "item_description": "Crispy and spicy paneer will spice up your taste quotient. Creamy sauce and crispy lettuce topping will sooth that extra spice",
                    "item_name_thai": "",
                    "item_description_thai": "",
                    "taxslab": 0,
                    "quantity": 500,
                    "mrp": 165,
                    "price": 165,
                    "is_discount": 0,
                    "start_datetime": null,
                    "end_datetime": null,
                    "discount_percentage": null,
                    "item_type": "0",
                    "item_packaging_charge": "0",
                    "customise_count": 0,
                    "tax_slab": null,
                    "item_images": [
                      {
                        "id": 436357,
                        "restaurant_menu_item_id": 2578826,
                        "image_name": "deonde_menu_16704944294157.jpg",
                        "vendor_id": 41472,
                        "restaurant_id": 5118,
                        "created_at": "2022-12-08T10:13:49.000000Z",
                        "updated_at": "2022-12-08T10:13:49.000000Z"
                      }
                    ],
                    "itemtags": []
                  },
                  {
                    "restaurant_menu_item_id": 2578827,
                    "restaurant_menu_id": 77708,
                    "is_featured": "0",
                    "item_name": "BIGSPICY \nPANEER WRAP",
                    "item_description": "Exquisitely picked, soft and tender paneer overwhelmed with a fiery, crunchy batter and dressed with fresh veggies & seasonings. Generously topped with creamy sauce and a dash of mustard & melted cheese that will surprise your tastes buds each time you sink your teeth into it.",
                    "item_name_thai": "",
                    "item_description_thai": "",
                    "taxslab": 0,
                    "quantity": 500,
                    "mrp": 160,
                    "price": 160,
                    "is_discount": 0,
                    "start_datetime": null,
                    "end_datetime": null,
                    "discount_percentage": null,
                    "item_type": "0",
                    "item_packaging_charge": "0",
                    "customise_count": 0,
                    "tax_slab": null,
                    "item_images": [
                      {
                        "id": 436358,
                        "restaurant_menu_item_id": 2578827,
                        "image_name": "deonde_menu_16704944299008.jpg",
                        "vendor_id": 41472,
                        "restaurant_id": 5118,
                        "created_at": "2022-12-08T10:13:49.000000Z",
                        "updated_at": "2022-12-08T10:13:49.000000Z"
                      }
                    ],
                    "itemtags": []
                  },
                  {
                    "restaurant_menu_item_id": 2578828,
                    "restaurant_menu_id": 77708,
                    "is_featured": "0",
                    "item_name": "BIGSPICY \nCHICKEN WRAP",
                    "item_description": "Enjoy tender, juicy chicken coats with a hot & crispy batter and dressed with fresh salad consisting of lettuce, onions, tomatoes & seasonings. Further topped with delicious, creamy sauce and supple cheese slices that'll make your tongue tingle with joy.",
                    "item_name_thai": "",
                    "item_description_thai": "",
                    "taxslab": 0,
                    "quantity": 500,
                    "mrp": 185,
                    "price": 185,
                    "is_discount": 0,
                    "start_datetime": null,
                    "end_datetime": null,
                    "discount_percentage": null,
                    "item_type": "1",
                    "item_packaging_charge": "0",
                    "customise_count": 0,
                    "tax_slab": null,
                    "item_images": [
                      {
                        "id": 436359,
                        "restaurant_menu_item_id": 2578828,
                        "image_name": "deonde_menu_16704944295304.jpg",
                        "vendor_id": 41472,
                        "restaurant_id": 5118,
                        "created_at": "2022-12-08T10:13:50.000000Z",
                        "updated_at": "2022-12-08T10:13:50.000000Z"
                      }
                    ],
                    "itemtags": []
                  },
                  {
                    "restaurant_menu_item_id": 2578829,
                    "restaurant_menu_id": 77708,
                    "is_featured": "0",
                    "item_name": "McSPICY CHICKEN",
                    "item_description": "Tender and juicy boneless chicken thigh meat coated in crispy batter with a kick of spice topped with a creamy sauce and crispy shredded lettuce will have you craving for more!",
                    "item_name_thai": "",
                    "item_description_thai": "",
                    "taxslab": 0,
                    "quantity": 600,
                    "mrp": 180,
                    "price": 180,
                    "is_discount": 0,
                    "start_datetime": null,
                    "end_datetime": null,
                    "discount_percentage": null,
                    "item_type": "1",
                    "item_packaging_charge": "0",
                    "customise_count": 0,
                    "tax_slab": null,
                    "item_images": [
                      {
                        "id": 436360,
                        "restaurant_menu_item_id": 2578829,
                        "image_name": "deonde_menu_16704944304220.jpg",
                        "vendor_id": 41472,
                        "restaurant_id": 5118,
                        "created_at": "2022-12-08T10:13:50.000000Z",
                        "updated_at": "2022-12-08T10:13:50.000000Z"
                      }
                    ],
                    "itemtags": []
                  }
                ]
              }
            ]
        },
        {
          "restaurant_cate_id": 1000,
          "restaurant_id": 3700,
          "cate_name": "category-2",
          "cate_description": "this is first cate",
          "menu":
            [
              {
                "restaurant_menu_id": 57585,
                "restaurant_id": 3700,
                "color_code": null,
                "menu_name": "Dental Care",
                "name": "Dental Care",
                "menu_description": "",
                "is_display_image": "1",
                "menu_image": "",
                "image": "",
                "item_counts": 18,
                "menu_item_detail": [
                  {
                    "restaurant_id": 3700,
                    "item_id": "1931653",
                    "is_featured": "0",
                    "item_name": "Closeup Complete ToothBrush Soft 1 Pc",
                    "item_description": "Pack Size: 1 Piece",
                    "image": "https://storage.googleapis.com/deonde-prod/media/Restaurant_Menu_Item_image/40818/3700/deonde_menu_16385124717.jpg",
                    "item_images": [
                      {
                        "image_name": "https://storage.googleapis.com/deonde-prod/media/Restaurant_Menu_Item_image/40818/3700/deonde_menu_16385124717.jpg"
                      }
                    ],
                    "item_tax": 0,
                    "custom_details": [

                    ],
                    "quantity": 999,
                    "time_slot": "",
                    "is_sold_out": "0",
                    "mrp": 6,
                    "price": 6,
                    "is_alcoholic": "0",
                    "item_type": "2",
                    "item_packaging_charge": "0",
                    "is_customization": 0,
                    "item_tags": [

                    ]
                  },
                  {
                    "restaurant_id": 3700,
                    "item_id": "1931654",
                    "is_featured": "0",
                    "item_name": "Colgate 360° Whole Mouth Clean Brush Medium With Tongue Cleaner    ",
                    "item_description": "Pack Size: 1 Piece",
                    "image": "https://storage.googleapis.com/deonde-prod/media/Restaurant_Menu_Item_image/40818/3700/deonde_menu_16385124726272.jpg",
                    "item_images": [
                      {
                        "image_name": "https://storage.googleapis.com/deonde-prod/media/Restaurant_Menu_Item_image/40818/3700/deonde_menu_16385124726272.jpg"
                      }
                    ],
                    "item_tax": 0,
                    "custom_details": [

                    ],
                    "quantity": 999,
                    "time_slot": "",
                    "is_sold_out": "0",
                    "mrp": 7,
                    "price": 7,
                    "is_alcoholic": "0",
                    "item_type": "2",
                    "item_packaging_charge": "0",
                    "is_customization": 0,
                    "item_tags": [

                    ]
                  }
                ]
              },
              {
                "restaurant_menu_id": 57585,
                "restaurant_id": 3700,
                "color_code": null,
                "menu_name": "Spicy Delights",
                "name": "Spicy Delights",
                "menu_description": "",
                "is_display_image": "1",
                "menu_image": "",
                "image": "",
                "item_counts": 18,
                "menu_item_detail": [
                  {
                    "restaurant_menu_item_id": 2578826,
                    "restaurant_menu_id": 77708,
                    "is_featured": "0",
                    "item_name": "McSPICY PANEER",
                    "item_description": "Crispy and spicy paneer will spice up your taste quotient. Creamy sauce and crispy lettuce topping will sooth that extra spice",
                    "item_name_thai": "",
                    "item_description_thai": "",
                    "taxslab": 0,
                    "quantity": 500,
                    "mrp": 165,
                    "price": 165,
                    "is_discount": 0,
                    "start_datetime": null,
                    "end_datetime": null,
                    "discount_percentage": null,
                    "item_type": "0",
                    "item_packaging_charge": "0",
                    "customise_count": 0,
                    "tax_slab": null,
                    "item_images": [
                      {
                        "id": 436357,
                        "restaurant_menu_item_id": 2578826,
                        "image_name": "deonde_menu_16704944294157.jpg",
                        "vendor_id": 41472,
                        "restaurant_id": 5118,
                        "created_at": "2022-12-08T10:13:49.000000Z",
                        "updated_at": "2022-12-08T10:13:49.000000Z"
                      }
                    ],
                    "itemtags": []
                  },
                  {
                    "restaurant_menu_item_id": 2578827,
                    "restaurant_menu_id": 77708,
                    "is_featured": "0",
                    "item_name": "BIGSPICY \nPANEER WRAP",
                    "item_description": "Exquisitely picked, soft and tender paneer overwhelmed with a fiery, crunchy batter and dressed with fresh veggies & seasonings. Generously topped with creamy sauce and a dash of mustard & melted cheese that will surprise your tastes buds each time you sink your teeth into it.",
                    "item_name_thai": "",
                    "item_description_thai": "",
                    "taxslab": 0,
                    "quantity": 500,
                    "mrp": 160,
                    "price": 160,
                    "is_discount": 0,
                    "start_datetime": null,
                    "end_datetime": null,
                    "discount_percentage": null,
                    "item_type": "0",
                    "item_packaging_charge": "0",
                    "customise_count": 0,
                    "tax_slab": null,
                    "item_images": [
                      {
                        "id": 436358,
                        "restaurant_menu_item_id": 2578827,
                        "image_name": "deonde_menu_16704944299008.jpg",
                        "vendor_id": 41472,
                        "restaurant_id": 5118,
                        "created_at": "2022-12-08T10:13:49.000000Z",
                        "updated_at": "2022-12-08T10:13:49.000000Z"
                      }
                    ],
                    "itemtags": []
                  },
                  {
                    "restaurant_menu_item_id": 2578828,
                    "restaurant_menu_id": 77708,
                    "is_featured": "0",
                    "item_name": "BIGSPICY \nCHICKEN WRAP",
                    "item_description": "Enjoy tender, juicy chicken coats with a hot & crispy batter and dressed with fresh salad consisting of lettuce, onions, tomatoes & seasonings. Further topped with delicious, creamy sauce and supple cheese slices that'll make your tongue tingle with joy.",
                    "item_name_thai": "",
                    "item_description_thai": "",
                    "taxslab": 0,
                    "quantity": 500,
                    "mrp": 185,
                    "price": 185,
                    "is_discount": 0,
                    "start_datetime": null,
                    "end_datetime": null,
                    "discount_percentage": null,
                    "item_type": "1",
                    "item_packaging_charge": "0",
                    "customise_count": 0,
                    "tax_slab": null,
                    "item_images": [
                      {
                        "id": 436359,
                        "restaurant_menu_item_id": 2578828,
                        "image_name": "deonde_menu_16704944295304.jpg",
                        "vendor_id": 41472,
                        "restaurant_id": 5118,
                        "created_at": "2022-12-08T10:13:50.000000Z",
                        "updated_at": "2022-12-08T10:13:50.000000Z"
                      }
                    ],
                    "itemtags": []
                  }
                ]
              }
            ]
        }
      ],
      selectedMenuId: ''
    };
  },

  computed: {
    ...mapGetters("order", ["isPickupDelivery"]),

    filteredData() {
      this.recommendedList = [];

      return Object.keys(this.menus).reduce((a, cKey) => {
        const data = this.filterValue(this.menus[cKey]);

        if (data != undefined && data.length) {
          let menuName = this.menus[cKey].menu_name;
          let nerecomm = data.find(itm => itm.is_featured == '1');
          if (nerecomm != undefined) {
            this.recommendedList.push(nerecomm);
          }
          a[cKey] = data;
          a[cKey].menu_name = menuName;
          a[cKey].item_counts = this.menus[cKey].item_counts;
          a[cKey].restaurant_menu_id = this.menus[cKey].restaurant_menu_id;
        } else {
          a[cKey] = this.menus[cKey];
        }

        if (this.selectType.length == 0) {
          this.filterItemType();
        }

        return a;
      }, {});
    },

    filteredItemData() {
      return Object.keys(this.menusdata).reduce((a, cKey) => {
        this.recommendedList = [];
        const data = this.filterValue(this.menusdata);
        if (data != undefined && data.length) {
          let menuName = this.menusdata.menu_name;
          a = data;
          a.menu_name = menuName;
          a.item_counts = 0;//this.menusdata.item_counts;
          a.restaurant_menu_id = this.menusdata.restaurant_menu_id;
          data.forEach(item => {
            if ((item.item_type == '0' || item.item_type == '1' || item.item_type == '2')) {
              this.itemType.push(item.item_type);
            }
          })
        }
        if (this.selectType.length == 0) {
          this.filterItemType();
        }
        return a;
      }, {});
    },

  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
    // this.getMenuWiseItemList(this.selectedMenuId); // Load first set of items
    let routerHasValue = this.$route.hash;
    if (routerHasValue) {
      this.routerItemId = routerHasValue.replace('#item', '');
    }
    this.vendorID = this.$auth.getVendorId();
    // localStorage.setItem('restaurantId', this.$route.params.restaurant_id)
    if (this.$route.params && this.$route.params.restaurant_id && this.$route.params.restaurant_id != "") {
      let restId = this.$route.params.restaurant_id
      this.restaurantID = this.base64_decode(restId);
    } else {
      this.restaurantID = this.restautantDetails && this.restautantDetails.restaurant_id;
      localStorage.setItem('restaurantID', this.restaurantID)
    }


    //this.itemlayout = this.$auth.setting && this.$auth.setting.restaurant_item_layout;
    this.itemlayout = this.restautantDetails && this.restautantDetails.restaurant_item_layout;
    if (this.itemlayout == '') {
      this.itemlayout = 1;
    }
    //this.filteredMenuData /* This function for multiple category menu and item display for loop */

    if (this.restautantDetails && this.$auth.setting && this.restautantDetails.item_counts > this.$auth.setting.item_counts) {
      this.isSearchHide = false;
      //this.categoryMenuProduct();
      this.getCategoryWiseMenuList();
    } else {
      if (this.restautantDetails && this.restautantDetails.menu_category_count < 0) {
        // this.getAllProducts();
        this.getCategoryWiseMenuList();
      } else {
        this.isSearchHide = true;
        this.getCategoryWiseMenuList();
        // this.getAllProducts();
      }
    }
    this.restTags = this.restautantDetails.restaurant_tags;


  },

  methods: {
    ...mapActions("product", ["getMenuProductList"]),
    ...mapActions("restaurant", ["getRestaurantMenuCategoryList", "getMenuWiseItem", "getCategoryWiseMenu"]),

    filterItemType() {
      let uniqueType = [];
      if (this.itemType.length > 0) {
        $.each(this.itemType, function (i, el) {
          if ($.inArray(el, uniqueType) === -1) uniqueType.push(el);
        });
      }
      if (uniqueType.length == '1' && !uniqueType.includes('2')) {
        this.listItemVeg = uniqueType;
      }
      return uniqueType;
    },

    itemFilterClick(type, tag) {
      if (this.selectType.indexOf(type) === -1) {
        this.selectType.push(type)
      } else {
        let newArray = this.selectType.filter(e => e !== type);
        this.selectType = newArray;
      }
    },

    //Search Filter
    filterValue(content) {
      let itemProduct = content.menu_item_detail;
      if (itemProduct) {
        return itemProduct.filter(item => {
          if (this.selectType.length > 0) {
            let newdata = this.selectType.findIndex(type => type === item.item_type);
            let newTagsSearch = this.selectType.findIndex(type => (item.item_tags.some(x => x.name == type)));
            if (newdata != -1 || newTagsSearch != -1) {
              return item;
            }
          } else {
            let itemUpper = item.item_name.toLowerCase();
            let searchUpper = this.search.toLowerCase();
            return itemUpper.indexOf(searchUpper) > -1;
          }
        });
      }
      // return content
    },
    openMenuCategoryList(id) {
      this.recommendedList = [];
      this.menus = [];
      this.menuCategryId = id;
      this.categoryMenuProduct();
    },

    categoryMenuProduct() {
      this.loading = true;
      this.is_menu_skel_loader = true;
      this.getMenuProductList({
        date: "",
        restaurant_id: this.restaurantID,
        vendor_id: this.vendorID,
        skip: 0,
        starttime: "",
        is_langauge: this.$store.state.lang.locale,
        total_record: 1000,
        cityname: "",
        latitude: localStorage.getItem("latitude"),
        delivery_type_time_slots: this.isPickupDelivery,
        menu_category_id: this.menuCategryId,
        // menu_category_id: localStorage.getItem("menuCatID"),
        longitude: localStorage.getItem("longitude"),
      }).then((data) => {
        if (data.code == 200) {
          this.loading = false;
          this.menus = data.Result;
          let itemtypeArray = [];
          this.menus.forEach(element => {
            if (element.menu_item_detail && element.menu_item_detail.length > 0) {
              element.menu_item_detail.forEach(elm => {
                itemtypeArray.push(elm.item_type);
              });
            }
          });
          this.routerItemId = localStorage.getItem("menuCatID");
          this.itemType = itemtypeArray;
          let findfirst = this.menus.find(el => el.item_counts > 0);
          if (findfirst && this.recommendedList.length > 0) {
            this.active_el = findfirst.restaurant_menu_id;
          }
          if (this.routerItemId > 0) {
            setTimeout(() => this.activeMenu(this.routerItemId), 1200);
          }
          let element = document.getElementById('menucateID-' + this.menuCategryId);
          $(".menucate-cls").removeClass("active");
          if (element != null) {
            element.className += " active";
          }
          this.is_menu_skel_loader = false;
        } else if (data.code == 101) {
          this.menus = [];
          if (this.routerItemId > 0) {
            setTimeout(() => this.activeMenu(this.routerItemId), 1200);
          }
          let element = document.getElementById('menucateID-' + this.menuCategryId);
          $(".menucate-cls").removeClass("active");
          if (element != null) {
            element.className += " active";
          }
          this.is_menu_skel_loader = false;
          this.loading = false;
        } else {
          this.is_menu_skel_loader = false;
          this.loading = false;
        }
      });

    },

    //ProductDetails Popup Modal Open
    openProductDeails(value) {
      window.$("#item-popup").modal("show");
      window.$("#item-popup").on('shown.bs.modal', function () {
      });

      window.$("#item-popup").on('hidden.bs.modal', () => {
        if (this.$refs.childComponent) {
          this.$refs.childComponent.productDetail = null
          this.$refs.childComponent.selectedCustomisation = {
            customize_type: {
            }
          }
        }
      });
      this.$refs.childComponent.openProductModel(value.item_id);
    },

    // openProductDeails(value) {
    //   window.$("#item-popup").modal("show");
    //   window.$("#item-popup").on('shown.bs.modal', function () {
    //   });
    //   window.$("#item-popup").on('hidden.bs.modal', () => {
    //     this.$refs.childComponent = {
    //       productDetail: null
    //     },
    //     this.$refs.childComponent.selectedCustomisation = {
    //       customize_type: {
    //       }
    //     }
    //   });
    //   this.$refs.childComponent.openProductModel(value.item_id);
    // },

    // Active Menu
    async activeMenu(itemId) {
      let id = 'item' + itemId;
      this.selectedSection = id;
      // if (!this.isSearchHide) {
      if (this.restautantDetails && this.restautantDetails.menu_category_count == 0) {
        this.is_menu_skel_loader = true;
        // this.menusdata = [];
        this.menusdata = {};
        this.skiped = 0;
        this.scrollPosition = 0;
        this.total_record = 20;
        window.scrollTo(0, 0);
        await this.getMenuWiseItemList(itemId);
      }

      // }
      // setTimeout(() => {
      //   // Get current scroll position

      //   let scrollY = window.pageYOffset;
      //   let element = document.getElementById(id);
      //   if (element) {
      //     if (this.routerItemId > 0 && this.routerItemIdStatus) {
      //       const sectionHeight = element.offsetHeight;
      //       // const sectionTop = element.offsetTop - 80;
      //       let sectionId = element.getAttribute("id").replace('item', '');
      //       this.active_el = sectionId;
      //       this.routerItemIdStatus = false;
      //       var menuScrollEl = document.getElementById('menuitem' + itemId);
      //       menuScrollEl.scrollIntoView({ behavior: "instant" });
      //       let y = element.getBoundingClientRect().top + window.pageYOffset - 100;
      //       window.scrollTo({ top: y, behavior: 'instant' });
      //     } else {
      //       let y = element.getBoundingClientRect().top + window.pageYOffset - 100;
      //       window.scrollTo({ top: y, behavior: 'instant' });
      //     }
      //   }
      //   if (localStorage.getItem("menuCatID")) {
      //     localStorage.setItem("menuCatID", '');
      //   }
      // }, 100);
    },




    //Category Wise Menu Display pagination.
    // getCategoryWiseMenuList() {
    //   if (this.restautantDetails && this.restautantDetails.menu_category_count == 0) {
    //     this.is_menu_category = false;
    //     this.loading = true;
    //     this.getCategoryWiseMenu({
    //       date: "",
    //       // restaurant_id: this.restaurantID,
    //       restaurant_id: this.restaurantID,
    //       vendor_id: this.vendorID,
    //       starttime: "",
    //       is_langauge: this.$store.state.lang.locale,
    //     }).then((data) => {
    //       if (data.code == 200) {
    //         this.loading = false;
    //         this.is_menu_skel_loader = false;
    //         this.menus = data.Result;
    //         let findfirst = this.menus.find(el => el.item_counts > 0);
    //         if (findfirst) {
    //           this.active_el = findfirst.restaurant_menu_id;
    //           this.getMenuWiseItemList(findfirst.id);
    //         }
    //       } else {
    //         this.is_menu_skel_loader = false;
    //         this.loading = false;
    //       }
    //     });
    //   } else if (this.restautantDetails && this.restautantDetails.menu_category_count > 0) {
    //     this.is_menu_category = true;
    //     this.getRestaurantMenuCategoryList({
    //       restaurant_id: this.restaurantID,
    //       vendor_id: this.vendorID,
    //       is_langauge: this.$store.state.lang.locale,
    //       latitude: localStorage.getItem("latitude"),
    //       longitude: localStorage.getItem("longitude"),
    //     }).then((data) => {
    //       if (data.code == 200) {
    //         this.loading = false;
    //         this.cateMenus = data.Result;
    //         this.newCateClassAdd = "cateNewClass";
    //         if (localStorage.getItem("menuCatID") == null || localStorage.getItem("menuCatID") == '') {
    //           if (this.cateMenus && this.cateMenus[0]) {
    //             this.menuCategryId = this.cateMenus[0].id;
    //             this.categoryMenuProduct();
    //           }
    //         } else {
    //           this.openMenuCategoryList(localStorage.getItem("menuCatID"));
    //           // localStorage.setItem("menuCatID", '');
    //         }
    //       } else {
    //         this.is_menu_skel_loader = false;
    //         this.loading = false;
    //       }
    //     });
    //   }
    // },


    getCategoryWiseMenuList() {
      if (this.$auth.setting && this.$auth.setting.product_type && this.$auth.setting.product_type.id == 2 && this.$auth.setting.store_selection == 0 && this.restautantDetails.menu_category_count == 0) {
        this.is_menu_category = false;
        this.loading = true;
        this.getCategoryWiseMenu({
          date: "",
          restaurant_id: this.restaurantID,
          vendor_id: this.vendorID,
          starttime: "",
          is_langauge: this.$store.state.lang.locale,
          // menu_category_id: this.menu_catgory_id,
        }).then((data) => {
          if (data.code == 200) {
            this.loading = false;
            this.is_menu_skel_loader = false;
            this.menus = data.Result;
            let findfirst = this.menus.find(el => el.item_counts > 0);
            if (localStorage.getItem("setMenuCategoryId")) {
              this.getMenuWiseItemList(localStorage.getItem("setMenuCategoryId"));
            } else {
              if (findfirst) {
                this.active_el = findfirst.restaurant_menu_id;
                this.getMenuWiseItemList(findfirst.id);
              }
            }
            localStorage.setItem("menuCatID", '');
          } else {
            this.is_menu_skel_loader = false;
            this.loading = false;
          }
        });
      } else if (this.restautantDetails && this.restautantDetails.menu_category_count == 0) {
        this.is_menu_category = false;
        this.loading = true;
        this.getCategoryWiseMenu({
          date: "",
          // restaurant_id: this.restaurantID,
          restaurant_id: this.restaurantID,
          vendor_id: this.vendorID,
          starttime: "",
          is_langauge: this.$store.state.lang.locale,
        }).then((data) => {
          if (data.code == 200) {
            this.loading = false;
            this.is_menu_skel_loader = false;
            this.menus = data.Result;
            let findfirst = this.menus.find(el => el.item_counts > 0);
            if (findfirst) {
              this.active_el = findfirst.restaurant_menu_id;
              this.getMenuWiseItemList(findfirst.id);
            }
          } else {
            this.is_menu_skel_loader = false;
            this.loading = false;
          }
        });
      }
      else if (this.restautantDetails && this.restautantDetails.menu_category_count > 0) {
        this.is_menu_category = true;
        this.getRestaurantMenuCategoryList({
          restaurant_id: this.restaurantID,
          vendor_id: this.vendorID,
          is_langauge: this.$store.state.lang.locale,
          latitude: localStorage.getItem("latitude"),
          longitude: localStorage.getItem("longitude"),
        }).then((data) => {
          if (data.code == 200) {
            this.loading = false;
            this.cateMenus = data.Result;
            this.newCateClassAdd = "cateNewClass";
            if (localStorage.getItem("menuCatID") == null || localStorage.getItem("menuCatID") == '') {
              if (this.cateMenus && this.cateMenus[0]) {
                this.menuCategryId = this.cateMenus[0].id;
                this.categoryMenuProduct();
              }
            } else {
              this.openMenuCategoryList(localStorage.getItem("menuCatID"));
              // localStorage.setItem("menuCatID", '');
            }
          } else {
            this.is_menu_skel_loader = false;
            this.loading = false;
          }
        });
      }
    },

    //Menu Wise item Display pagination.
    // getMenuWiseItemList(itemId) {
    //   this.is_menu_category = false;
    //   this.loading = true;
    //   this.is_menu_skel_loader = true
    //   this.getMenuWiseItem({
    //     date: "",
    //     restaurant_id: this.restaurantID,
    //     vendor_id: this.vendorID,
    //     skip: 0,
    //     starttime: "",
    //     is_langauge: this.$store.state.lang.locale,
    //     total_record: 10000,
    //     menu_id: itemId,
    //   }).then((data) => {
    //     if (data.code == 200) {
    //       this.loading = false;
    //       this.is_menu_skel_loader = false;
    //       this.menusdata = data.Result[0];
    //     } else {
    //       this.loading = false;
    //       this.is_menu_skel_loader = false;
    //     }
    //   });
    // },

    getMenuWiseItemList(itemId, append = false) {
      this.selectedMenuId = itemId;
      if (this.isFetching) return; // Prevent multiple calls
      this.isFetching = true;
      this.is_menu_category = false;
      this.loading = false; // Show loader only on first fetch
      this.is_menu_skel_loader = true;
      this.getMenuWiseItem({
        date: "",
        restaurant_id: this.restaurantID,
        vendor_id: this.vendorID,
        skip: this.skiped,
        starttime: "",
        is_langauge: this.$store.state.lang.locale,
        total_record: this.total_record,
        menu_id: itemId,
      }).then((data) => {
        if (data.code == 200) {
          // this.totalItems = this.menu_item_count; // Store total items count
          if (append) {
            this.menusdata.menu_item_detail = [
              ...this.menusdata.menu_item_detail,
              ...data.Result[0].menu_item_detail,
            ];
          } else {
            this.menusdata = data.Result[0];
          }
          const selectedMenu = this.menus.find((item) => item.id == itemId);
          if (selectedMenu) {
            this.totalItems = selectedMenu.item_counts;
          }
          // this.menus.forEach((item, index) => {
          //   this.totalItems = item.item_counts; // Store total items coun
          // })
          this.skiped += this.total_record;
          // this.total_record += 20; // Increase records count for next fetch
        }

        this.loading = false;
        this.is_menu_skel_loader = false;
        this.isFetching = false;
      }).catch(() => {
        this.loading = false;
        this.is_menu_skel_loader = false;
        this.isFetching = false;
      });
    },

    handleScroll() {
      this.scrollPosition = window.scrollY;
      const bottom = window.innerHeight + this.scrollPosition >= document.documentElement.scrollHeight - 100;
      const hasMoreItems = this.menusdata && this.menusdata.menu_item_detail && this.menusdata.menu_item_detail.length < this.totalItems;
      if (bottom && !this.isFetching && hasMoreItems) {
        this.getMenuWiseItemList(this.selectedMenuId, true);
      }
    }

  },


};
</script>

<style>
.menuSkel {
  border-radius: 30px !important;
}

.skel-loader {
  display: flex;
}

.sticcitem img {
  height: 30px;
  object-fit: contain;
}

.menu_tag {
  padding-top: 0.5rem !important;
  padding-bottom: .5rem !important;
}
</style>
