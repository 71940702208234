<template>
    <footer class="footer">
        <div class="container">
            <div class="copy-right">
                <p v-if="$auth && $auth.setting && $auth.setting.show_contact_developer == 'Yes'">
                    <!-- <span v-if="this.$auth.getVendorId() == '40818'">Powered by <a target="_blank"
                            href="https://deonde.co/">{{
                                $auth.getAppName() }}</a></span>
                    <span v-else>Powered by <a href="/">{{ $auth.getAppName() }}</a></span> -->
                    <span>Powered by <a target="_blank" href="https://deonde.co/">Deonde</a></span>
                </p>
                <p v-else-if="this.$auth.getVendorId() == '42466'">
                    <span><a>Copyright </a>&#169;
                        <a target="_blank">{{ $t('Ethics Retails Chain Service Pvt Ltd.') }}</a> {{ year }}</span>
                </p>
                <p v-else>
                    <span><a>Copyright </a>&#169;
                        <a target="_blank">{{ $auth.getAppName() }}</a> {{ year }}</span>
                </p>
                <!-- <cookie-law theme="dark-lime">
        <div slot="message">This {{$auth.getAppName()}} uses cookies to provide you with a great user experience. Please accept cookies for excellent optimal performance</div>
      </cookie-law> -->
            </div>
        </div>
    </footer>
</template>

<script>
import CookieLaw from "vue-cookie-law";
import { computed } from "vue";
export default {
    name: "footerpage",
    components: { CookieLaw },
    computed: {
        year() {
            const currentDate = new Date();
            return currentDate.getFullYear();
        },
        currentRouteName() {
            return this.$route.name;
        },
    },
    mounted() {
        this.updateIntercom();
    },
    watch: {
        $route() {
            this.updateIntercom();
        },
    },
    methods: {
        shouldShowIntercom() {
            const routeNamesToShowIntercom = ["restaurant"];
            return routeNamesToShowIntercom.includes(this.currentRouteName);
        },
        updateIntercom() {
            if (this.$auth.user && !this.shouldShowIntercom()) {
                // change condition for intercom
                // this.$auth.setting.is_intercom_configurations == 'Yes' && 
                if (this.$auth && this.$auth.setting && this.$auth.setting.intercom_details.is_enable == true) {
                    this.initializeIntercom();
                    //  document.getElementsByClassName('intercom-lightweight-app-launcher intercom-launcher')[0].style.display = "block";
                    let intercomLauncher = document.getElementsByClassName('intercom-lightweight-app-launcher intercom-launcher')[0]
                    if (intercomLauncher) {
                        intercomLauncher.style.display = "block";
                    }
                }
            } else {
                // document.getElementsByClassName('intercom-lightweight-app-launcher intercom-launcher')[0].style.display = "none"
                let intercomLauncher = document.getElementsByClassName('intercom-lightweight-app-launcher intercom-launcher')[0]
                if (intercomLauncher) {
                    intercomLauncher.style.display = "none";
                }

                this.removeIntercom();
            }
        },
        removeIntercom() {

        },
        initializeIntercom() {
            window.intercomSettings = {
                api_base: "https://api-iam.intercom.io",
                app_id: "d5zbw9m3",
                name: this.$auth.user.user_name,
                user_id: this.$auth.user.user_id,
                email: this.$auth.user.email,
                created_at: this.$auth.user.created_at,
            };

            window.v_id = this.$auth.setting.intercom_configuration_appid;

            (function () {
                var w = window;
                var ic = w.Intercom;
                if (typeof ic === "function") {
                    ic("reattach_activator");
                    ic("update", w.intercomSettings);
                } else {
                    var d = document;
                    var i = function () {
                        i.c(arguments);
                    };
                    i.q = [];
                    i.c = function (args) {
                        i.q.push(args);
                    };
                    w.Intercom = i;
                    var l = function () {
                        var s = d.createElement("script");
                        s.type = "text/javascript";
                        s.async = true;
                        s.src = "https://widget.intercom.io/widget/" + w.v_id;
                        var x = d.getElementsByTagName("script")[0];
                        x.parentNode.insertBefore(s, x);
                    };
                    if (document.readyState === "complete") {
                        l();
                    } else if (w.attachEvent) {
                        w.attachEvent("onload", l);
                    } else {
                        w.addEventListener("load", l, false);
                    }
                }
            })();
        },
    },
};
</script>
